import { Paragraph } from '../../common-components';
import { InfoLabel } from '../../common-components/InfoLabel';
import useTranslation from '../../hooks/useTranslation';
import {
  NewLookBannerContainer,
  NewLookBannerBox,
} from './NewLookBannerStyles';

export const NewLookBanner = ({ href }: { href: string }) => {
  const t = useTranslation();

  return (
    <NewLookBannerContainer>
      <NewLookBannerBox href={href}>
        <InfoLabel label={t('txt_new_look_banner_badge')} />
        <Paragraph>{t('txt_new_look_banner_message')}</Paragraph>
      </NewLookBannerBox>
    </NewLookBannerContainer>
  );
};
