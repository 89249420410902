import tw, { styled } from 'twin.macro';

export const NewLookBannerContainer = styled.div(() => [tw`w-full p-5 pb-0`]);

export const NewLookBannerBox = styled.a(() => [
  tw`max-w-7xl mx-auto no-underline`,
  tw`flex flex-col items-center gap-4 px-4 py-5`,
  tw`lg:flex-row lg:gap-6 lg:px-8 lg:py-6`,
  tw`bg-blue-100 rounded-medium text-grey-0`,
]);
